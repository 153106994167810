$orange: #f7801e;
$orangeBorderDark: #bc5300;
$darkblue: #042b3b;
$cyan: #00bee8;
///////////// Mobile Header  ////////////////




.header-container {
	position: fixed;
	top: 0;
	width: 100%;
	height: 70px;
	line-height: 70px;
	-webkit-box-shadow: 0 4px 3px rgba(0,0,0,.26);
	box-shadow: 0 4px 3px rgba(0,0,0,.26);
	z-index: 9999;
	background: #1a3749;

	a:active, a:hover {
		text-decoration: none;
		color: $orange;
	}
	.logo-container {
		padding-left: 25px;
		display: block;
		height: 100%;
		margin-right: 20px;
		float: left;
		.logo {
			height: 100%;
		}
	}
	.hamburger {
		height: 100%;
		right: 25px;
		//top: 31px;
	}
	.move-plan-button {
		position: absolute;
		right: 90px;
		height: 70px;
		width: 70px;
		margin-top: -5px;
		fill: #fff;
	}
	.mobile-navbar {
		position: fixed;
		top: 70px;
		height: 100%;
		width: 75%;
		max-width: 480px;
		color: #fff;
		overflow: auto;
		-webkit-transform: translateX(-100%);
		-ms-transform: translateX(-100%);
		transform: translateX(-100%);
		-webkit-transition: -webkit-transform .3s ease-in-out;
		transition: -webkit-transform .3s ease-in-out;
		-o-transition: transform .3s ease-in-out;
		transition: transform .3s ease-in-out;
		transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
		z-index: 1;
	}
	.hamburger {
		height: 100%;
		right: 25px;
		position: absolute;
		width: 35px;
		height: 70px;
		-webkit-transition: -webkit-transform .2s;
		transition: -webkit-transform .2s;
		-o-transition: transform .2s;
		transition: transform .2s;
		transition: transform .2s,-webkit-transform .2s;
		.bar {
			position: absolute;
			top: 44%;
			width: 100%;
			height: 4px;
			background: #fff;
			-webkit-transition: background 0s .2s;
			-o-transition: background 0s .2s;
			transition: background 0s .2s;
			&:before {
				position: absolute;
				display: block;
				left: 0;
				width: 100%;
				height: 4px;
				background-color: #fff;
				content: "";
				top: -9px;
				-webkit-transition: top .2s .2s,-webkit-transform .2s 0s;
				transition: top .2s .2s,-webkit-transform .2s 0s;
				-o-transition: top .2s .2s,transform .2s 0s;
				transition: top .2s .2s,transform .2s 0s;
				transition: top .2s .2s,transform .2s 0s,-webkit-transform .2s 0s;
			}
			&:after {
				bottom: -9px;
				position: absolute;
				display: block;
				left: 0;
				width: 100%;
				height: 4px;
				background-color: #fff;
				content: "";
				-webkit-transition: bottom .2s .2s,-webkit-transform .2s 0s;
				transition: bottom .2s .2s,-webkit-transform .2s 0s;
				-o-transition: bottom .2s .2s,transform .2s 0s;
				transition: bottom .2s .2s,transform .2s 0s;
				transition: bottom .2s .2s,transform .2s 0s,-webkit-transform .2s 0s;
			}
		}
	}
}

.mobile-menu-open {
	.hamburger {
		.bar {
			background: 0 0;
			-webkit-transition: background 0s .2s;
			-o-transition: background 0s .2s;
			transition: background 0s .2s;

			&:before {
				top: 0;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
				-webkit-transition: top .2s 0s,-webkit-transform .2s .2s;
				-o-transition: top .2s 0s,transform .2s .2s;
				transition: top .2s 0s,transform .2s .2s;
				transition: top .2s 0s,transform .2s .2s,-webkit-transform .2s .2s;
			}
			&:after {
				bottom: 0;
				-webkit-transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				transform: rotate(-45deg);
				-webkit-transition: bottom .2s 0s,-webkit-transform .2s .2s;
				-o-transition: bottom .2s 0s,transform .2s .2s;
				transition: bottom .2s 0s,transform .2s .2s;
				transition: bottom .2s 0s,transform .2s .2s,-webkit-transform .2s .2s;
			}
		}
	}
}

/////////// Dimmer ////////////

.dimmer {
	position: fixed;
	top: 70px;
	height: calc(100% - 70px);
	width: 100%;
	opacity: 0;
	background-color: rgba(#000, 0.5);
	transform: translateX(-100%);
	transition: opacity 0.2s;
	z-index: 10;
}

////////// Mobile Nav Menu /////////////

.mobile-navbar {
	position: fixed;
	top: 70px;
	width: 75%;
	max-width: 480px;
	color: #fff;
	overflow: auto;
	-webkit-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	transform: translateX(-100%);
	-webkit-transition: -webkit-transform .3s ease-in-out;
	-o-transition: transform .3s ease-in-out;
	transition: transform .3s ease-in-out;
	transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
	z-index: 1;
	.menu__level {
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		overflow-y: auto;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		list-style-type: none;
		background: #1a3749;
		background: -webkit-gradient(linear,left top,left bottom,from(#1a3749),color-stop(13%,#1a3548),color-stop(14%,#1b364b),color-stop(42%,#193447),color-stop(44%,#1a354a),color-stop(47%,#183346),color-stop(55%,#193248),color-stop(58%,#193344),color-stop(59%,#1a3347),color-stop(60%,#183145),to(#193344));
		background: -webkit-linear-gradient(top,#1a3749 0,#1a3548 13%,#1b364b 14%,#193447 42%,#1a354a 44%,#183346 47%,#193248 55%,#193344 58%,#1a3347 59%,#183145 60%,#193344 100%);
		background: -o-linear-gradient(top,#1a3749 0,#1a3548 13%,#1b364b 14%,#193447 42%,#1a354a 44%,#183346 47%,#193248 55%,#193344 58%,#1a3347 59%,#183145 60%,#193344 100%);
		background: linear-gradient(to bottom,#1a3749 0,#1a3548 13%,#1b364b 14%,#193447 42%,#1a354a 44%,#183346 47%,#193248 55%,#193344 58%,#1a3347 59%,#183145 60%,#193344 100%);
	}
	.menu__back--hidden {
		pointer-events: none;
		opacity: 0;
	}
}

/////////// Mobile nav when opened ////////////

.mobile-menu-open {
	.dimmer {
		opacity: 1;
		transform: translateX(0);
	}
	.mobile-navbar {
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
}

.menu__level {
	background: #1a3749;
	background: linear-gradient(to bottom, #1a3749 0%, #1a3548 13%, #1b364b 14%, #193447 42%, #1a354a 44%, #183346 47%, #193248 55%, #193344 58%, #1a3347 59%, #183145 60%, #193344 100%);

}

.mobile-menu {
	margin: 0;
	text-transform: uppercase;
	border-bottom: 1px solid rgba(255, 255, 255, 0.06);
	line-height: 55px;
	padding-inline-start: 0;

}
.mobile-submenu {
	>.mobile-submenu-list-container {
		&.first {
			margin-top: 55px
		}
	}
}

.mobile-menu-item,
.mobile-submenu-item {

	position: relative;
	height: 55px;
	padding-left: 40px;
	border-top: 1px solid rgba(255, 255, 255, 0.06);
	border-bottom: 1px solid rgba(0, 0, 0, 0.17);


	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		background-color: $orange;
		content: '';
		transition: width 0.3s ease;
	}

	&.active {
		color: $orange;
	}

	&.active {
		&:before {
			width: 5px;
		}
	}
}

.mobile-submenu-cta-container {
	text-align: center;
}
.mobile-submenu-cta-link {
	text-transform: none;
	display: inline-block;
	width: 80%;
	margin: 20px auto;
	border: 1px solid $orangeBorderDark;
	line-height: 2.5;
	background: $orange;
	color: white;
	border-radius: 3px;
	text-shadow: 0px 1px 1px $orangeBorderDark;
}

.breadcrumb-container {
	height: 55px;
	padding: 0 20px;
	text-transform: none;
	position: fixed;
	background: $darkblue;
	width: 100%;
	z-index: 1;
	top: 0;
	left: 0;
	> * {
		display: inline-block;
		line-height: 55px;
		vertical-align: middle;
	}
	.breadcrumb-back {
		background: url("~/public/img/left-arrow-chevron.svg") no-repeat center left / contain;
		padding-right: 40px;
		height: 20px;
	}
	.breadcrumb-current {
		font-size: 24px;
		padding-top: 1px;
	}
}

.mobile-menu-item-link,
.mobile-submenu-item-link,
.mobile-services-item-link {
	display: block;
	height: 100%;
	color: inherit;
	opacity: .7;
}
.mobile-menu-benefits-link {
	color: $cyan;
}

.mobile-submenu-list {
	margin: 0;
}

.mobile-submenu-item {
	text-transform: none;
}
.mobile-submenu-item-link {
	line-height: 55px;
}
.mobile-submenu-list-title {
	display: block;
	background: $darkblue;
	height: 40px;
	line-height: 40px;
	padding-left: 20px;
}


input.mobile-menu-item-link {
	width: 100%;
	padding: 0;
	line-height: 55px;
	text-align: left;
	text-transform: uppercase;
	border: 0;
	outline: none;
	border-radius: 0;
	background-color: transparent;
	box-shadow: none;
	-webkit-appearance: none;
}

.logout-form,
.mobile-submenu-item-link,
.mobile-services-item-link,
.mobile-actions-menu {
	-webkit-transform: translateX(-40%);
	-ms-transform: translateX(-40%);
	transform: translateX(-40%);
	-webkit-transition: -webkit-transform 0.6s, opacity 1.2s;
	transition: transform 0.6s, opacity 1.2s;
}

.mobile-user-name-holder {
	padding-left: 40px;
	&:before {
		position: absolute;
		top: 0;
		left: 8px;
		width: 30px;
		height: inherit;
		content: '';
		background: url('~/public/img/icon_user.svg') center center no-repeat;
		background-size: contain;
	}
}

.mobile-actions-menu {
	padding: 20px;
	border-bottom: none;
}

.mobile-users-section {
	font-size: 14px;
	&:after {
		content: '';
		display: block;
		height: 1px;
		margin: 20px 0 40px;
		background: rgba(#fff, 0.06);
		background: -moz-linear-gradient(left, rgba(#fff, 0.06) 0%, #f7801e 50%, #f7801e 51%, rgba(#fff, 0.06) 100%);
		background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(#fff, 0.06)), color-stop(50%, #f7801e), color-stop(51%, #f7801e), color-stop(100%, rgba(#fff, 0.06)));
		background: -webkit-linear-gradient(left, rgba(#fff, 0.06) 0%, #f7801e 50%, #f7801e 51%, rgba(#fff, 0.06) 100%);
		background: -o-linear-gradient(left, rgba(#fff, 0.06) 0%, #f7801e 50%, #f7801e 51%, rgba(#fff, 0.06) 100%);
		background: -ms-linear-gradient(left, rgba(#fff, 0.06) 0%, #f7801e 50%, #f7801e 51%, rgba(#fff, 0.06) 100%);
		background: linear-gradient(to right, rgba(#fff, 0.06) 0%, #f7801e 50%, #f7801e 51%, rgba(#fff, 0.06) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(255, 255, 255, 0.06)', endColorstr='rgba(255, 255, 255, 0.06)', GradientType=1);
	}
}
.mobile-user-name-holder {
	position: relative;
	height: 55px;
	padding-left: 40px;
	color: #f7801e;
	border-bottom: 1px solid rgba(0,0,0,.17);
	text-transform: uppercase;
	overflow: hidden;
	opacity: .7;
	height: 55px;
}


.mobile-user-menu {
	&, .mobile-menu-item:last-child {
		border-bottom: none;
	}
}

.mobile-main-menu {
	font-size: 14px;
	.mobile-menu-item:not(.active) .mobile-menu-item-link,
	.mobile-menu-item:not(.active) .mobile-services-item-link,
	.mobile-submenu-item:not(.active) .mobile-submenu-item-link {
		opacity: 0.7;
	}

	&, .mobile-menu-item:first-child {
		border-top: none;
	}
}

.mobile-action-menu-item {
	width: 50%;
  padding: 0 1rem;
}
.mobile-cta-button {
	display: block;
	height: 50px;
	line-height: 50px;
	text-align: center;
	color: #f7801e;
	border: 1px solid #f7801e;
	-webkit-transition: all .25s;
	-o-transition: all .25s;
	transition: all .25s;
}

.menu__level {
	position: absolute;
	top: 0;
	left: 0;
	visibility: hidden;
	overflow: hidden;
	overflow-y: scroll;
	width: calc(100%);
	height: calc(100% - 70px);
	margin: 0;
	padding: 0;
	list-style-type: none;
	ul {
		list-style: none;
	}

}

.menu__level--current {
	visibility: visible;
}

.menu__breadcrumbs {
	font-size: 0.65em;
	line-height: 1;
	position: relative;
	padding: 10px 20px;

	a {
		font-weight: bold;
		display: inline-block;
		cursor: pointer;
		vertical-align: middle;
		letter-spacing: 1px;
		text-transform: uppercase;

		&:last-child {
			pointer-events: none;
		}
		&:hover {
			color: #8182e0;
		}
	}
}

.menu__back {
	font-size: 1.05em;
	position: absolute;
	z-index: 100;
	top: 0;
	right: 2.25em;
	margin: 0;
	padding: 1.365em 0.65em 0 0;
	cursor: pointer;
	color: #2a2b30;
	border: none;
	background: none;

	&:hover,
	&:focus {
		color: #fff;
		outline: none;
	}
}

.menu__back--hidden {
	pointer-events: none;
	opacity: 0;
}
